import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  HeroButton,
  HeroContentWrapper,
  HeroSectionWrapper,
  HeroTitle,
} from "./Home.style";
import rightArrow from "../../assets/images/right.png";
import { useNavigate } from "react-router-dom";
import main from "../../assets/images/Rectangle 14.png";
import arrow from "../../assets/arrow.png";
import snake from "../../assets/snake.png";
import "./home.css";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    console.log("Navigating to firms");
    navigate("/firms");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div
      className="hero-wrapper w-full flex flex-start justify-between space-between items-center"
      style={{
        // position: "absolute",
        top: 0,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
        // zIndex: 100,
        marginTop: "10px",
      }}
    >
      <div className="hero-first-text">
        <p
          className="first-text-main"
          style={{
            fontWeight: 300,
          }}
        >
          Navigate{" "}
          <span
            style={{
              color: "#03A9F4",
              fontWeight: 400,
            }}
          >
            Legal
          </span>
          <br />
          <span
            style={{
              color: "#03A9F4",
              fontWeight: 400,
            }}
          >
            Challenges{" "}
          </span>
          with Trust
        </p>
        {/* Updated Button for better styling */}
        <button
          onClick={handleClick}
          className="hero-button"
          // onMouseEnter={(e) =>
          //   (e.currentTarget.style.backgroundColor = "#0288D1")
          // } // Lighter shade on hover
          // onMouseLeave={(e) =>
          //   (e.currentTarget.style.backgroundColor = "#03A9F4")
          // }
        >
          Get a Consult
        </button>
      </div>
      <div className="girl-image pl-5 w-[60%]">
        <img
          src={main}
          className="girl-image-main"
          alt=""
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>
  );
};

export default HeroSection;
