import React from "react";
import law1 from "../../../assets/Law-assets/law1.png";
import law2 from "../../../assets/Law-assets/law2.png";
import law3 from "../../../assets/Law-assets/law3.png";
import { Typography } from "@mui/material";

const CivilandCommercial = () => {
  return (
    <>
      <div className="container mt-4 px-0">
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "18px",
            color: "#63666A",
            fontFamily: "Inter",
            wordSpacing: "0.1px",
            fontWeight: 500,
            lineHeight: "2rem",
          }}
        >
          Civil law, also known as civil or commercial law, is a broad area of
          law that deals with private disputes between individuals or
          organizations. It primarily focuses on conflicts that do not involve
          criminal charges but rather address issues related to personal rights,
          contracts, property, and compensation for damages
        </p>

        <div className="row d-flex ">
          <div className="col-md-5 col-sm-12 mt-4">
            <div className="">
              <img src={law1} alt="" className="img-fluid" />
            </div>

            <div className="mt-5">
              <img src={law2} alt="" className="img-fluid" />
            </div>
          </div>

          <div
            className="col-md-7  col-sm-12 leading-7 text-dark poppins-500 "
            style={{ fontSize: "18px" }}
          >
            <Typography
              className="ps-0"
              sx={{
                fontWeight: "400",
                fontSize: { xs: "26px", lg: "34px" },
                margin: "20px auto",
                fontFamily: "poppins,sans-serif",
              }}
            >
              Key Aspects of Civil / Commercial Law
            </Typography>

            <ul
              className="list-disc "
              style={{ textAlign: "left", listStyle: "none" }}
            >
              <li>
                {" "}
                <Typography
                  className="ps-0"
                  sx={{
                    fontSize: { xs: "20px", lg: "26px" },
                    fontWeight: 400,
                    fontFamily: "poppins,sans-serif",
                  }}
                >
                  Contract Law
                </Typography>
                <p
                  className=""
                  style={{
                    textAlign: "justify",
                    fontSize: "18px",
                    color: "#63666A",
                    fontFamily: "Inter",
                    wordSpacing: "0.1px",
                    fontWeight: 500,
                    lineHeight: "2rem",
                  }}
                >
                  {" "}
                  Deals with agreements between individuals or entities,
                  ensuring that contracts are legally enforceable and that
                  parties fulfill their obligations.
                </p>
              </li>{" "}
              <br />
              <li>
                {" "}
                <Typography
                  className="ps-0"
                  sx={{
                    fontSize: { xs: "20px", lg: "26px" },
                    fontWeight: 400,
                    fontFamily: "poppins,sans-serif",
                  }}
                >
                  Property Law
                </Typography>
                <p
                  className=""
                  style={{
                    textAlign: "justify",
                    fontSize: "18px",
                    color: "#63666A",
                    fontFamily: "Inter",
                    wordSpacing: "0.1px",
                    fontWeight: 500,
                    lineHeight: "2rem",
                  }}
                >
                  Governs the ownership, use, and transfer of property,
                  including real estate, personal possessions, and intellectual
                  property.
                </p>
              </li>{" "}
              <br />
              <li>
                {" "}
                <Typography
                  className="ps-0"
                  sx={{
                    fontSize: { xs: "20px", lg: "26px" },
                    fontWeight: 400,
                    fontFamily: "poppins,sans-serif",
                  }}
                >
                  Tort Law
                </Typography>
                <p
                  className=""
                  style={{
                    textAlign: "justify",
                    fontSize: "18px",
                    color: "#63666A",
                    fontFamily: "Inter",
                    wordSpacing: "0.1px",
                    fontWeight: 500,
                    lineHeight: "2rem",
                  }}
                >
                  Addresses civil wrongs or injuries caused by one party to
                  another. It includes areas like negligence, defamation,
                  product liability, and personal injury claims.
                </p>
              </li>{" "}
              <br />
              <li>
                {" "}
                <Typography
                  className="ps-0"
                  sx={{
                    fontSize: { xs: "20px", lg: "26px" },
                    fontWeight: 400,
                    fontFamily: "poppins,sans-serif",
                  }}
                >
                  Family Law
                </Typography>
                <p
                  className=""
                  style={{
                    textAlign: "justify",
                    fontSize: "18px",
                    color: "#63666A",
                    fontFamily: "Inter",
                    wordSpacing: "0.1px",
                    fontWeight: 500,
                    lineHeight: "2rem",
                  }}
                >
                  Covers matters related to family relationships and domestic
                  issues, including divorce, child custody, adoption, alimony,
                  and child support.
                </p>
              </li>{" "}
              <br />
              <li>
                {" "}
                <Typography
                  className="ps-0"
                  sx={{
                    fontSize: { xs: "20px", lg: "26px" },
                    fontWeight: 400,
                    fontFamily: "poppins,sans-serif",
                  }}
                >
                  Employment Law
                </Typography>
                <p
                  className=""
                  style={{
                    textAlign: "justify",
                    fontSize: "18px",
                    color: "#63666A",
                    fontFamily: "Inter",
                    wordSpacing: "0.1px",
                    fontWeight: 500,
                    lineHeight: "2rem",
                  }}
                >
                  Regulates the relationship between employers and employees,
                  addressing issues such as contracts, wages, workplace
                  discrimination, and wrongful termination.{" "}
                </p>
              </li>{" "}
              <br />
            </ul>
          </div>
        </div>
      </div>

      <div className="intro container pt-0 mt-0 px-0">
        <ul
          className="list-disc"
          style={{ textAlign: "left", listStyle: "none" }}
        >
          <li className="">
            <Typography
              className="ps-0"
              sx={{
                fontSize: { xs: "20px", lg: "26px" },

                fontWeight: 400,
                fontFamily: "poppins,sans-serif",
              }}
            >
              Preponderance of Evidence{" "}
            </Typography>
            <p
              className=""
              style={{
                textAlign: "justify",
                fontSize: "18px",
                color: "#63666A",
                fontFamily: "Inter",
                wordSpacing: "0.1px",
                fontWeight: 500,
                lineHeight: "2rem",
              }}
            >
              Unlike criminal cases, where guilt must be proved beyond a
              reasonable doubt, civil cases are decided based on a preponderance
              of evidence, meaning the evidence shows that one side's version of
              the facts is more likely.{" "}
            </p>{" "}
          </li>
          <br />{" "}
          <li>
            {" "}
            <Typography
              className="ps-0"
              sx={{
                fontSize: { xs: "20px", lg: "26px" },
                fontWeight: 400,
                fontFamily: "poppins,sans-serif",
              }}
            >
              Compensation{" "}
            </Typography>
            <p
              className=""
              style={{
                textAlign: "justify",
                fontSize: "18px",
                color: "#63666A",
                fontFamily: "Inter",
                wordSpacing: "0.1px",
                fontWeight: 500,
                lineHeight: "2rem",
              }}
            >
              {" "}
              Civil law often aims to compensate the harmed party by providing
              monetary damages or specific remedies rather than punishment.{" "}
            </p>{" "}
          </li>{" "}
          <br />
          <li>
            <Typography
              className="ps-0"
              sx={{
                fontSize: { xs: "20px", lg: "26px" },
                fontWeight: 400,
                fontFamily: "poppins,sans-serif",
              }}
            >
              Settlements and Dispute Resolution{" "}
            </Typography>
            <p
              className=""
              style={{
                textAlign: "justify",
                fontSize: "18px",
                color: "#63666A",
                fontFamily: "Inter",
                wordSpacing: "0.1px",
                fontWeight: 500,
                lineHeight: "2rem",
              }}
            >
              {" "}
              Parties in civil cases often have opportunities to settle disputes
              through negotiation, mediation, or arbitration before going to
              court.{" "}
            </p>{" "}
          </li>
          <br />
          <li>
            {" "}
            <Typography
              className="ps-0"
              sx={{
                fontSize: { xs: "20px", lg: "26px" },
                fontWeight: 400,
                fontFamily: "poppins,sans-serif",
              }}
            >
              Private Party Involvement
            </Typography>
            <p
              className=""
              style={{
                textAlign: "justify",
                fontSize: "18px",
                color: "#63666A",
                fontFamily: "Inter",
                wordSpacing: "0.1px",
                fontWeight: 500,
                lineHeight: "2rem",
              }}
            >
              {" "}
              Civil disputes involve private parties seeking remedies for harm
              caused, distinct from criminal cases where the state prosecutes an
              individual for violating laws.{" "}
            </p>{" "}
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <img
          className="md:mx-12 img-fluid coom-img"
          src={law3}
          alt=""
          style={{
            width: "95%",
            margin: "auto",
          }}
        />
      </div>
    </>
  );
};

export default CivilandCommercial;
