import React from "react";
import sectionImg from "../../assets/about-assets/section1-img.png";
import advice from "../../assets/about-assets/advice.svg";
import review from "../../assets/about-assets/review.svg";
import layer from "../../assets/about-assets/layers.svg";
import sevice from "../../assets/about-assets/services.svg";
import "./about.css";

export default function Section1() {
  const list = [
    {
      img: advice,
      name: "Get Legal Advice",
      Description: `Donec nec justo eget felis facilisis 
          fermentum. Aliquam porttitor mauris sit amet`,
    },
    {
      img: review,
      name: "Document Reviewing",
      Description: `Donec nec justo eget felis facilisis 
          fermentum. Aliquam porttitor mauris sit amet`,
    },
    {
      img: layer,
      name: "Great Lawyers",
      Description: `Donec nec justo eget felis facilisis 
          fermentum. Aliquam porttitor mauris sit amet`,
    },
    {
      img: sevice,
      name: "Customer Services",
      Description: `Donec nec justo eget felis facilisis 
          fermentum. Aliquam porttitor mauris sit amet`,
    },
  ];

  return (
    <>
      <div className="container mt-5">
        <div className="row mt-sm-3 mt-xs-2 mx-md-4 mx-3 flex flex-row-reverse">
          {/* Image section */}
          <div className="col-md-5 order-md-1 order-2 mb-4 mb-md-0">
            <img
              src={sectionImg}
              alt="Section image"
              className="img-fluid imgg_1"
            />
          </div>
          {/* Content section */}
          <div className="col-md-6 ms-md-5 order-md-2 order-1">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="row">
                {/* Title */}
                <p
                  className="first-text-main"
                  style={{
                    fontWeight: 300,
                  }}
                >
                  About{" "}
                  <span
                    style={{
                      color: "#03A9F4",
                      fontWeight: 400,
                    }}
                  >
                    Firm
                  </span>
                  <br />
                </p>

                {/* Subtitle */}
                <p className="mb-3">Leading Criminal Defense Lawyer Team</p>
                {/* List of services */}
                {list.map((item, i) => {
                  return (
                    <div className="col-md-6 col-12 mb-4" key={i}>
                      <div className="card border-0">
                        {/* Image */}
                        <div className="d-flex justify-content-md-start justify-content-center">
                          <img
                            className="card-img-top mt-4"
                            src={item.img}
                            style={{ maxWidth: "50px" }}
                            alt={item.name}
                          />
                        </div>
                        {/* Card body */}
                        <div className="card-body text-md-start text-center ps-0">
                          {/* Service name */}
                          <p
                            className="poppins-700 mt-2"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "20.8px",
                            }}
                          >
                            {item.name}
                          </p>
                          {/* Description */}
                          <p
                            className="poppins-500 text-dark mt-2"
                            style={{
                              fontSize: "14px",
                              fontWeight: 300,
                              lineHeight: "22px",
                            }}
                          >
                            {item.Description}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
