import React, { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch } from "react-redux";
import { getAllPlans } from "../../_redux/features/PaymentSlice";
import { useNavigate } from "react-router";
import UnSubscribe from "../UnSubcribeModal/UnSubscribeModal";
import { useActiveItem } from "../../ActiveItemContext";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const PLAN_OPTIONS = {
  starter: [
    "Profile Listing (starter)",
    "Priority of Listing (Alphabetical Order)",
    "Intro Lawyers/firm (basic Information)",
    "Contact details (Address, Phone.no)",
    "Client's Comment (Limited)",
    "No. of specialty areas (1)",
    "Display advertising(N/A)",
    "Ad tracking & Engagement (N/A)",
  ],
  advanced: [
    "Profile Listing (Advanced)",
    "Priority of Listing (Alphabetical Order)",
    "Intro Lawyers/firm(Limited intro size and history)",
    "Contact details (Address, Phone.no, Email and website Link)",
    "Client's Comment (All)",
    "No. of specialty areas (3)",
    "Display advertising (Available, Display Banner)",
    "Ad tracking & Engagement (N/A)",
  ],
  elite: [
    "Profile Listing(Elite)",
    "Priority of Listing (Ranked Higher)",
    "Intro Lawyers/firm (Full intro size, history, experience, specialties and old cases)",
    "Contact details (Address, phone.no, Email, website link)",
    "Client's Comment (All)",
    "No. of specialty areas (Unlimited)",
    "Display advertising (Available, Display, Banner, Upload judgment, Photos & Video)",
    "Ad tracking & Engagement (Available)",
  ],
};

const SubScriptionScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetActive } = useActiveItem();
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const result = await dispatch(getAllPlans());
        const plans = result.payload?.plans || [];
        setData(plans);
      } catch (error) {
        console.error("Failed to fetch plans", error);
      }
    };

    fetchPlans();
  }, [dispatch]);

  const buyPlan = (planId) => {
    if (user?.user?.plan_id) {
      toast.error(
        "You already have current subscription, please cancel it first"
      );
      return;
    }
    if (user) {
      navigate("/checkout", {
        state: { user_id: user?.user?._id, planId },
      });
      resetActive(null);
    }
    if (id) navigate("/checkout", { state: { user_id: id, planId } });
  };

  const showUnSub = () => setModalShow(!modalShow);

  const renderPlanCard = (planType, planName, planPrice, planFeatures) => {
    const isCurrentPlan = user?.user?.plan_id === data?.[planType]?.id;

    // Conditional styling based on whether the plan is selected
    const cardStyle = isCurrentPlan
      ? {
          backgroundColor: "#1b1d1f",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }
      : {};

    const textColor = isCurrentPlan ? "white" : ""; // Ensure text is always white for visibility

    return (
      <div className="col-lg-4 col-md-4 col-sm-12 mt-md-0 mt-5">
        <div
          className={`card h-100 shadow rounded-4 ${
            isCurrentPlan ? "" : "subscription-card"
          } `}
          style={cardStyle} // Applying the dynamic card styling
        >
          <div className="card-body" style={{ padding: "30px 40px" }}>
            <p className="fs-2 poppins-600" style={{ color: textColor }}>
              {planName} {isCurrentPlan && "(Current Plan)"}
            </p>
            <p className="poppins-500 my-2" style={{ color: textColor }}>
              {planName} description
            </p>

            {/* Pricing */}
            <div className="price">
              <div className="d-flex align-items-center">
                <div className="doller">
                  <p
                    className="poppins-700"
                    style={{ fontSize: "42px", color: textColor }}
                  >
                    $
                  </p>
                </div>
                <div>
                  <p
                    className="poppins-600 px-1"
                    style={{ fontSize: "48px", color: textColor }}
                  >
                    {planPrice}
                  </p>
                </div>
                <div className="d-flex align-items-end mt-3">
                  <p
                    className="poppins-500 text-dark m-0"
                    style={{ color: textColor }}
                  >
                    Per month
                  </p>
                </div>
              </div>
            </div>

            {/* Plan Status */}
            <div className="my-3">
              <button
                className="btn poppins-400 px-5"
                style={{ color: "white", background: "#ff7a06" }}
                disabled={isCurrentPlan}
                onClick={() => {
                  buyPlan(data?.[planType]?.id);
                }}
              >
                {isCurrentPlan ? "Current Plan" : planName}
              </button>
            </div>

            {/* Features */}
            <div className="mt-3">
              <p className="fs-2 poppins-600 my-2" style={{ color: textColor }}>
                Features
              </p>
              <p
                className={`${
                  isCurrentPlan ? "" : "text-dark"
                } poppins-500 my-3`}
                style={{ color: textColor }}
              >
                Everything you get in this plan
              </p>
              <div className="items-include mt-4">
                {planFeatures.map((feature, i) => {
                  const [featureName, featureDetail] = feature.split("(");
                  return (
                    <div className="d-flex my-3" key={i}>
                      <div>
                        <DoneIcon
                          style={{
                            color:
                              featureDetail === "N/A" ? "white" : "#ff7a06",
                            fontSize: "28px",
                          }}
                        />
                      </div>
                      <p
                        className={`${
                          isCurrentPlan ? "" : "text-dark"
                        } poppins-500 mx-2`}
                        style={{ color: textColor }}
                      >
                        {featureName}
                        <strong style={{ color: "#ff7a06" }}>
                          {" "}
                          ({featureDetail})
                        </strong>
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="my-md-4">
                <button
                  className="btn btn-lg poppins-400 w-100"
                  style={{ color: "white", background: "#ff7a06" }}
                  disabled={isCurrentPlan}
                  onClick={() => buyPlan(data?.[planType]?.id)}
                >
                  {isCurrentPlan ? "Current" : "Buy Now"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {modalShow && (
        <div className="modalForUnsubscribed">
          <div className="modal-contentForUnsubscribed">
            <UnSubscribe closeWindow={showUnSub} />
          </div>
        </div>
      )}

      <div className="container mt-5 mb-5">
        {user?.user?._id && (
          <div className="row mx-4 mb-4">
            <div className="col-md-6">
              <h2 className="fs-2 fw-bold">Subscription Plans</h2>
            </div>
            {user.user.plan_id && (
              <div className="col-md-6 text-end">
                <button
                  className="btn btn-dark px-4 py-2"
                  style={{ fontWeight: "600" }}
                  disabled={user.user.plan_id === null}
                  onClick={showUnSub}
                >
                  Cancel Subscription
                </button>
              </div>
            )}
          </div>
        )}

        <div className="row mt-md-5 mx-md-5 mx-3">
          {renderPlanCard(0, "Starter", 50, PLAN_OPTIONS.starter)}
          {renderPlanCard(1, "Advanced", 100, PLAN_OPTIONS.advanced)}
          {renderPlanCard(2, "Elite", 170, PLAN_OPTIONS.elite)}
        </div>
      </div>
    </>
  );
};

export default SubScriptionScreen;
