import React from "react";
import { Typography } from "@mui/material";

const WillandProbate = () => {
  return (
    <div>
      <div className="mt-4">
        <div className="row">
          <div
            className="col-md-12  col-sm-12 col-12 poppins-300"
            style={{ display: "flex", alignItems: "center", textAlign: "left" }}
          >
            <p className="font-medium leading-7 poppins-500 ">
              <p
                className=""
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  color: "#63666A",
                  fontFamily: "Inter",
                  wordSpacing: "0.1px",
                  fontWeight: 500,
                  lineHeight: "2rem",
                }}
              >
                Wills and probate law are integral parts of estate planning and
                the legal process that handles the distribution of a deceased
                person's assets and the settling of their affairs
              </p>

              <Typography
                className="ps-0"
                sx={{
                  fontWeight: "400",
                  fontSize: { xs: "26px", lg: "34px" },
                  margin: "20px auto",
                  fontFamily: "poppins,sans-serif",
                }}
              >
                Key Aspects of Will & Probate Law
              </Typography>
              <ul
                className="list-disc my-4"
                style={{ textAlign: "left", listStyle: "none" }}
              >
                <li>
                  {" "}
                  <Typography
                    className="ps-0"
                    sx={{
                      fontSize: { xs: "20px", lg: "26px" },
                      fontWeight: 400,
                      fontFamily: "poppins,sans-serif",
                    }}
                  >
                    Last Will and Testament
                  </Typography>
                  <p
                    className=""
                    style={{
                      textAlign: "justify",
                      fontSize: "18px",
                      color: "#63666A",
                      fontFamily: "Inter",
                      wordSpacing: "0.1px",
                      fontWeight: 500,
                      lineHeight: "2rem",
                    }}
                  >
                    {" "}
                    A legal document that outlines a person's wishes regarding
                    the distribution of their assets, care of dependents, and
                    appointment of guardianship for minors after their death.{" "}
                  </p>{" "}
                </li>
                <br />{" "}
                <li>
                  {" "}
                  <Typography
                    className="ps-0"
                    sx={{
                      fontSize: { xs: "20px", lg: "26px" },
                      fontWeight: 400,
                      fontFamily: "poppins,sans-serif",
                    }}
                  >
                    Testator
                  </Typography>
                  <p
                    className=""
                    style={{
                      textAlign: "justify",
                      fontSize: "18px",
                      color: "#63666A",
                      fontFamily: "Inter",
                      wordSpacing: "0.1px",
                      fontWeight: 500,
                      lineHeight: "2rem",
                    }}
                  >
                    {" "}
                    The person creating the will, also known as the testator,
                    determines how their property and belongings should be
                    distributed among beneficiaries.{" "}
                  </p>{" "}
                </li>
                <br />{" "}
                <li>
                  <Typography
                    className="ps-0"
                    sx={{
                      fontSize: { xs: "20px", lg: "26px" },
                      fontWeight: 400,
                      fontFamily: "poppins,sans-serif",
                    }}
                  >
                    Executor
                  </Typography>
                  <p
                    className=""
                    style={{
                      textAlign: "justify",
                      fontSize: "18px",
                      color: "#63666A",
                      fontFamily: "Inter",
                      wordSpacing: "0.1px",
                      fontWeight: 500,
                      lineHeight: "2rem",
                    }}
                  >
                    {" "}
                    The person nominated in the will to carry out the testator's
                    instructions, manage the estate, pay debts, and distribute
                    assets to beneficiaries.{" "}
                  </p>{" "}
                </li>
                <br />{" "}
                <li>
                  <Typography
                    className="ps-0"
                    sx={{
                      fontSize: { xs: "20px", lg: "26px" },
                      fontWeight: 400,
                      fontFamily: "poppins,sans-serif",
                    }}
                  >
                    Witnesses and Legal Formalities{" "}
                  </Typography>
                  <p
                    className=""
                    style={{
                      textAlign: "justify",
                      fontSize: "18px",
                      color: "#63666A",
                      fontFamily: "Inter",
                      wordSpacing: "0.1px",
                      fontWeight: 500,
                      lineHeight: "2rem",
                    }}
                  >
                    {" "}
                    Requirements vary by jurisdiction, but wills typically need
                    to be signed by the testator and witnessed by two or more
                    witnesses to be considered legally valid.
                  </p>{" "}
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WillandProbate;
