import React, { useEffect } from "react";
import law from "../../assets/Law-assets/MainImg.png";
import "./Law.css";

import LawTabs from "./LawTabs/LawTabs";
import { Typography } from "@mui/material";
import MainNews from "../Home/MainNews";
import { GiInjustice } from "react-icons/gi";

const Law = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <br></br>
      <div className="font mt-sm-4">
        <div
          className=" bg-cover bg mx-auto img-main "
          style={{
            backgroundImage: `url(${law})`,
            objectFit: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            maxHeight: 700,
            position: "absolute",
            top: 0,
          }}
        >
          {" "}
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <GiInjustice color="white" size={80} />
            <p
              className="text-white text fonttextStyleDesktop"
              style={{
                fontFamily: "poppins",
                fontWeight: 500,
                fontSize: "50px",
                lineHeight: "65px",
              }}
            >
              Expert{" "}
              <span
                className="text"
                style={{
                  fontWeight: 700,
                }}
              >
                Legal
              </span>{" "}
              Guidance
            </p>
            <p
              className="text-white fonttextStyleDesktop"
              style={{
                fontFamily: "poppins",
                fontWeight: 500,
                fontSize: "50px",
                lineHeight: "65px",
              }}
            >
              at Your{" "}
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                Fingertips
              </span>
            </p>
            <p
              className="fonttextStyleMobile text-white"
              style={{
                fontFamily: "poppins",
                fontWeight: 500,
                fontSize: "18px",
                textAlign: "center !important",
                // lineHeight: "65px",
              }}
            >
              Expert Legal Guidance at your FingerTips{" "}
            </p>
            <p className="text-white fonttextStyleMobile">
              Leading criminal defense lawyer team
            </p>
            <p className="text-white fonttextStyleDesktop">
              Leading criminal defense lawyer team
            </p>
          </div>
        </div>
        <br></br>

        <div className="ps-0 mx-md-5 mx-4 mt-[32%]">
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: "30px",
                lg: "44px",
              },
              fontWeight: "300",
              marginBottom: "30px",
              fontFamily: "poppins",
            }}
          >
            Introduction To{" "}
            <span
              style={{
                fontWeight: "400",

                color: "#03A9F4",
              }}
            >
              Laws
            </span>
          </Typography>
          <p
            className=""
            style={{
              textAlign: "justify",
              fontSize: "18px",
              color: "#63666A",
              fontFamily: "Inter",
              wordSpacing: "0.1px",
              fontWeight: 500,
              lineHeight: "2rem",
            }}
          >
            Laws are the system of rules and regulations created and enforced by
            governmental or social institutions to regulate behavior. It
            provides a framework within which societies operate, defining
            rights, duties, and obligations for individuals, organizations, and
            governments
          </p>
        </div>

        <div className="container" style={{ margin: "40px auto" }}>
          <LawTabs />
        </div>

        {/* <h2 className="text-center my-5 poppins-600 fw-bold h4">Practice Attorneys</h2>
        <div className="row justify-content-center mx-12 my-5"  >
          <div className="col-md-12">
            <div className="row justify-content-center">
              <div className=" col-md-6">
                <img className=" img-fluid" src={law5} alt="img law-4" />
                <div className="text-center my-2">
                  <p>john Harts</p>
                  <p className="fs-4">Finance & Banking</p>
                  <h2>Read Profile</h2>
                </div>
              </div>
              <div className="col-md-6">
                <img className="img-fluid" src={law6} alt="img law-5" />
                <div className="text-center my-2">
                  <h2>Laura Griffin</h2>
                  <p className="fs-4">Finance & Banking</p>
                  <h2>Read Profile</h2>
                </div>
              </div>
            </div>
          </div>

        </div> */}

        {/* <div className="div">
          <Typography
            variant="h1"
            sx={{
              fontSize: {
                xs: "30px",
                lg: "50px",
              },
              textAlign: "center",
              fontWeight: "800",
              mb: {
                xs: "30px",
                lg: "60px",
              },
              fontFamily: "poppins,sans-serif",
            }}
          >
            News & Events
          </Typography> */}
        {/* <MainNews /> */}
        {/* </div> */}
      </div>
    </>
  );
};
export default Law;
