import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "react-bootstrap";

const TablePagination = ({ list, paginatedList }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(list)
    ? list.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // Logic to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(list?.length / itemsPerPage);

  useEffect(() => {
    paginatedList(currentItems);
  }, [currentPage, list]);

  const getPageNumbers = () => {
    const pages = [];
    const range = 2;
    const start = Math.max(2, currentPage - range);
    const end = Math.min(totalPages - 1, currentPage + range);

    pages.push(1);

    if (start > 2) {
      pages.push("...");
    }

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    if (end < totalPages - 1) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  return (
    <div className="row d-flex align-items-center my-4">
      <div className="col-md-6 col-sm-12">
        <span>
          Showing {indexOfFirstItem + 1}-
          {Math.min(indexOfLastItem, list?.length || 0)} of {list?.length || 0}
        </span>
      </div>
      <div className="col-md-6 col-sm-12 d-flex justify-content-end">
        <Stack spacing={2}>
          <Pagination size="sm">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </Pagination.Prev>

            {getPageNumbers().map((page, index) =>
              page === "..." ? (
                <Pagination.Ellipsis key={index} />
              ) : (
                <Pagination.Item
                  key={index}
                  active={page === currentPage}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </Pagination.Item>
              )
            )}

            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </Pagination.Next>
          </Pagination>
        </Stack>
      </div>
    </div>
  );
};

export default TablePagination;
