import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../assets/Logo/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseChimneyUser,
  faUser,
  faFileLines,
  faCalendarCheck,
  faNewspaper,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import SelectAccount from "../../Components/SelectAccount/SelectAccount";

const Main = ({ openSidebarToggle, OpenSidebar, toggleLogoutModal }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeItem, setActiveItem] = useState(0);

  useEffect(() => {
    // Set active item based on URL path
    const routes = [
      "/dashboard/Firm",
      "/dashboard/Firm/Team",
      "/dashboard/Firm/Case-Histories",
      "/dashboard/Firm/News",
      "/dashboard/Firm/Chat",
      "/dashboard/Firm/Profile",
      "/dashboard/Firm/Subscription",
    ];

    // Find the index of the current path in the routes array
    const currentIndex = routes.findIndex(
      (route) => location.pathname == route
    );
    setActiveItem(currentIndex !== -1 ? currentIndex : 0);
  }, [location.pathname]);

  const handleItemClick = (index) => {
    setActiveItem(index);
    if (openSidebarToggle) {
      OpenSidebar();
    }
  };

  const showLogoutScreen = () => {
    toggleLogoutModal();
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <aside
      id="sidebar"
      className={openSidebarToggle ? "sidebar-responsive" : ""}
      style={{ width: "280px" }}
    >
      <div className="sidebar-title">
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={handleHomeClick}
        >
          <img
            src={logo}
            className="rounded"
            alt=""
            style={{ width: "60px", height: "60px" }}
          />
          <h2>
            <b className="text-white">Brownie Link Services</b>
          </h2>
        </div>

        <span className="icon close_icon" onClick={OpenSidebar}></span>
      </div>

      <ul className="sidebar-list mt-5">
        <li
          className={`sidebar-list-item  ${activeItem === 0 ? "active" : ""}`}
          onClick={() => {
            handleItemClick(0);
            handleNavigate("/dashboard/Firm");
          }}
        >
          <Link className="d-flex align-items-center">
            <FontAwesomeIcon icon={faHouseChimneyUser} />
            <span className="px-3">Dashboard</span>
          </Link>
        </li>

        <li
          className={`sidebar-list-item ${activeItem === 1 ? "active" : ""}`}
          onClick={() => {
            handleItemClick(1);
            handleNavigate("/dashboard/Firm/Team");
          }}
        >
          <Link className="d-flex align-items-center">
            <FontAwesomeIcon icon={faFileLines} />
            <span className="px-3">Team</span>
          </Link>
        </li>
        <li
          className={`sidebar-list-item ${activeItem === 2 ? "active" : ""}`}
          onClick={() => {
            handleItemClick(2);
            handleNavigate("/dashboard/Firm/Case-Histories");
          }}
        >
          <Link className="d-flex align-items-center">
            <FontAwesomeIcon icon={faCalendarCheck} />{" "}
            <span className="px-3">Case Histories</span>
          </Link>
        </li>

        <li
          className={`sidebar-list-item ${activeItem === 3 ? "active" : ""}`}
          onClick={() => {
            handleItemClick(3);
            handleNavigate("/dashboard/Firm/News");
          }}
        >
          <Link className="d-flex align-items-center">
            <FontAwesomeIcon icon={faNewspaper} />{" "}
            <span className="px-3">News</span>
          </Link>
        </li>

        <li
          className={`sidebar-list-item ${activeItem === 4 ? "active" : ""}`}
          onClick={() => {
            handleItemClick(4);
            handleNavigate("/dashboard/Firm/Chat");
          }}
        >
          <Link className="d-flex align-items-center">
            <FontAwesomeIcon icon={faMessage} />{" "}
            <span className="px-3">Chat</span>
          </Link>
        </li>
        <li
          className={`sidebar-list-item ${activeItem === 5 ? "active" : ""}`}
          onClick={() => {
            handleItemClick(5);
            handleNavigate("/dashboard/Firm/Profile");
          }}
        >
          <Link className="d-flex align-items-center">
            <FontAwesomeIcon icon={faUser} />{" "}
            <span className="px-3">Profile</span>
          </Link>
        </li>

        <li
          className={`sidebar-list-item ${activeItem === 6 ? "active" : ""}`}
          onClick={() => {
            handleItemClick(6);
            handleNavigate("/dashboard/Firm/Subscription");
          }}
        >
          <Link className="d-flex align-items-center">
            <CrisisAlertIcon /> <span className="px-3">Subscription</span>
          </Link>
        </li>

        <li
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "260px",
            // right: 0,
            // zIndex: 999,
            background: "rgb(1, 10, 19)",
          }}
        >
          <SelectAccount toggleLogoutModal={showLogoutScreen} />
        </li>
      </ul>
    </aside>
  );
};

export default Main;
