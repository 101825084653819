import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../assets/Logo/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faMessage } from "@fortawesome/free-solid-svg-icons";
import SelectAccount from "../../Components/SelectAccount/SelectAccount";

const Main = ({ openSidebarToggle, OpenSidebar, toggleLogoutModal }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeItem, setActiveItem] = useState(0);

  const handleItemClick = (index) => {
    setActiveItem(index);
    if (openSidebarToggle) {
      OpenSidebar();
    }
  };
  useEffect(() => {
    // Set active item based on URL path
    const routes = ["/dashboard/Client", "/dashboard/Client/Chat"];

    // Find the index of the current path in the routes array
    const currentIndex = routes.findIndex(
      (route) => location.pathname == route
    );
    setActiveItem(currentIndex !== -1 ? currentIndex : 0);
  }, [location.pathname]);
  const showLogoutScreen = () => {
    toggleLogoutModal();
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <aside
      id="sidebar"
      className={openSidebarToggle ? "sidebar-responsive" : ""}
      style={{ width: "280px" }}
    >
      <div className="sidebar-title">
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={handleHomeClick}
        >
          <img
            src={logo}
            className="rounded"
            alt=""
            style={{ width: "60px", height: "60px" }}
          />
          <h2>
            <b className="text-white">Brownie Link Services</b>
          </h2>
        </div>

        <span className="icon close_icon" onClick={OpenSidebar}></span>
      </div>

      <ul className="sidebar-list mt-5">
        <li
          className={`sidebar-list-item  ${activeItem === 0 ? "active" : ""}`}
          onClick={() => {
            handleItemClick(0);
            handleNavigate("/dashboard/Client");
          }}
        >
          <Link className="d-flex align-items-center">
            <FontAwesomeIcon icon={faUser} />
            <span className="px-3">Profile</span>
          </Link>
        </li>

        <li
          className={`sidebar-list-item ${activeItem === 1 ? "active" : ""}`}
          onClick={() => {
            handleItemClick(1);
            handleNavigate("/dashboard/Client/Chat");
          }}
        >
          <Link className="d-flex align-items-center">
            <FontAwesomeIcon icon={faMessage} />{" "}
            <span className="px-3">Chat</span>
          </Link>
        </li>
      </ul>
      <div
        className="div"
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 999,
        }}
      >
        <SelectAccount toggleLogoutModal={showLogoutScreen} />
      </div>
    </aside>
  );
};

export default Main;
