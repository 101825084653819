import React from "react";
import { Typography } from "@mui/material";

const CriminalLaw = () => {
  return (
    <div>
      <div className="my-4">
        <div className="row">
          <div
            className="col-md-12  col-sm-12 col-12 poppins-500"
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "justify",
            }}
          >
            <p className="leading-7">
              <p
                className=""
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  color: "#63666A",
                  fontFamily: "Inter",
                  wordSpacing: "0.1px",
                  fontWeight: 500,
                  lineHeight: "2rem",
                }}
              >
                Criminal law is a branch of law that deals with offenses
                committed against the state or society as a whole. It
                encompasses statutes, regulations, and legal principles that
                define crimes, establish penalties for unlawful conduct, and
                govern the criminal justice process.
              </p>
              <Typography
                className="ps-0"
                sx={{
                  fontWeight: "400",
                  fontSize: { xs: "26px", lg: "34px" },
                  margin: "20px auto",
                  fontFamily: "poppins,sans-serif",
                }}
              >
                Key Aspects of Criminal Law
              </Typography>

              <ul
                className="list-disc my-4"
                style={{ textAlign: "left", listStyle: "none" }}
              >
                <li>
                  {" "}
                  <Typography
                    className="ps-0"
                    sx={{
                      fontSize: { xs: "20px", lg: "26px" },
                      fontWeight: 400,
                      fontFamily: "poppins,sans-serif",
                    }}
                  >
                    {" "}
                    Definition of Crime
                  </Typography>
                  <p
                    className=""
                    style={{
                      textAlign: "justify",
                      fontSize: "18px",
                      color: "#63666A",
                      fontFamily: "Inter",
                      wordSpacing: "0.1px",
                      fontWeight: 500,
                      lineHeight: "2rem",
                    }}
                  >
                    {" "}
                    Criminal law outlines specific behaviors or actions that are
                    considered illegal and punishable by law, such as murder,
                    theft, assault, fraud, drug trafficking, and more..
                  </p>{" "}
                </li>{" "}
                <br />
                <li>
                  {" "}
                  <Typography
                    className="ps-0"
                    sx={{
                      fontSize: { xs: "20px", lg: "26px" },
                      fontWeight: 400,
                      fontFamily: "poppins,sans-serif",
                    }}
                  >
                    Element ofCrime
                  </Typography>
                  <p
                    className=""
                    style={{
                      textAlign: "justify",
                      fontSize: "18px",
                      color: "#63666A",
                      fontFamily: "Inter",
                      wordSpacing: "0.1px",
                      fontWeight: 500,
                      lineHeight: "2rem",
                    }}
                  >
                    {" "}
                    To establish guilt, criminal law requires proof of both the
                    "mens rea" (culpable mental state or intent) and the "actus
                    reus" (guilty act or conduct) of the accused.
                  </p>{" "}
                </li>{" "}
                <br />
                <li>
                  {" "}
                  <Typography
                    className="ps-0"
                    sx={{
                      fontSize: { xs: "20px", lg: "26px" },
                      fontWeight: 400,
                      fontFamily: "poppins,sans-serif",
                    }}
                  >
                    Classification of Offenses
                  </Typography>
                  <p
                    className=""
                    style={{
                      textAlign: "justify",
                      fontSize: "18px",
                      color: "#63666A",
                      fontFamily: "Inter",
                      wordSpacing: "0.1px",
                      fontWeight: 500,
                      lineHeight: "2rem",
                    }}
                  >
                    {" "}
                    Crimes are categorized into different classes based on their
                    severity, ranging from misdemeanors (less serious offenses)
                    to felonies (more serious crimes).
                  </p>{" "}
                </li>{" "}
                <br />
                <li>
                  <Typography
                    className="ps-0"
                    sx={{
                      fontSize: { xs: "20px", lg: "26px" },
                      fontWeight: 400,
                      fontFamily: "poppins,sans-serif",
                    }}
                  >
                    Criminal Procedure
                  </Typography>
                  <br />
                  <p
                    className=""
                    style={{
                      textAlign: "justify",
                      fontSize: "18px",
                      color: "#63666A",
                      fontFamily: "Inter",
                      wordSpacing: "0.1px",
                      fontWeight: 500,
                      lineHeight: "2rem",
                    }}
                  >
                    Defines the legal procedures for investigating crimes,
                    gathering evidence, arresting suspects, conducting trials,
                    and determining guilt or innocence.
                  </p>{" "}
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CriminalLaw;
